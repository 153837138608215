.character-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s;
}
.hide > .character-wrapper {
    opacity: 0;
    transform: translateY(200%);
}
svg {
    width: 100%;
    height: 100%;
}