.App {
  text-align: center;
  font-size: 14px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.characters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: .5rem;
  .character {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #313337;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    font-size: 2em;
    padding: 10%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    flex-direction: column;
    overflow: hidden;
  }
}